import React from "react";
import { useState,useEffect } from "react";
import { Rnd } from "react-rnd";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DraggableIP = (props) => {
    const [width, setWidth] = useState(props.dataEntry.width);
    const [height, setHeight] = useState(props.dataEntry.height);
    const [ip, setIP] = useState(props.dataEntry.extraData[0].IP);
    const [position, setPosition] = useState({
      x: props.dataEntry.positionX,
      y: props.dataEntry.positionY,
    });
    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      console.log(res.data);
      setIP(res.data.IPv4)
    }
    useEffect( () => {
      getData()
    }, [])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div>
        <Rnd
          position={{ x: position.x, y: position.y }}
          onDragStop={(e, d) => {
            setPosition({ x: d.x, y: d.y });
            props.refreshDATA(
              props.dataEntry.elementId,
              props.dataEntry.elementCode,
              d.x,
              d.y,
              height,
              width,
              [...props.dataEntry.extraData],
              localStorage.getItem("currPage")
            );
          }}
        >
          <div>
              <h1>Your IP Address is:</h1><br/>
              <h4>{ip}</h4>
          </div>
  
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon/>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
              <input
                onChange={(e) => {
                  setWidth(e.target.value);
                  props.refreshDATA(
                    props.dataEntry.elementId,
                    props.dataEntry.elementCode,
                    position.x,
                    position.y,
                    height,
                    e.target.value,
                    [...props.dataEntry.extraData],
                    localStorage.getItem("currPage")
                  );
                }}
                value={width}
              ></input>
            </MenuItem>
            <MenuItem>
              <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
              <input
                onChange={(e) => {
                  setHeight(e.target.value);
                  props.refreshDATA(
                    props.dataEntry.elementId,
                    props.dataEntry.elementCode,
                    position.x,
                    position.y,
                    e.target.value,
                    width,
                    [...props.dataEntry.extraData],
                    localStorage.getItem("currPage")
                  );
                }}
                value={height}
              ></input>
            </MenuItem>
  
            <MenuItem
              onClick={(e) => {
                props.removeElementFromDATA(props.dataEntry.elementId);
              }}
            >
              Remove
            </MenuItem>
          </Menu>
          
        </Rnd>
      </div>
  )
}

export default DraggableIP