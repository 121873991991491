// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// // Your web app's Firebase configuration

// const firebaseConfig = {
//   apiKey: "AIzaSyB3WS1O8MA9eVvTZGGRYIO6mZLKtUhj0So",
//   authDomain: "patang-demo-67d23.firebaseapp.com",
//   projectId: "patang-demo-67d23",
//   storageBucket: "patang-demo-67d23.appspot.com",
//   messagingSenderId: "516166279798",
//   appId: "1:516166279798:web:c07e610269a3f35248b557"
// };

// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyAgX8iW-H4zNptmmrDtaLfePeEZrkDaoAo",

  authDomain: "ovautocd.firebaseapp.com",

  databaseURL: "https://ovautocd-default-rtdb.firebaseio.com",

  projectId: "ovautocd",

  storageBucket: "ovautocd.appspot.com",

  messagingSenderId: "151890157658",

  appId: "1:151890157658:web:a3677a0a19f46df006b863",

  measurementId: "G-W6DXY2QLVT"

};



// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const provider = new GoogleAuthProvider();
export const rdb = getDatabase(app);
export const storage = getStorage(app);

