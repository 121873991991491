import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

// import Card from "@mui/material/Card";
import uniqid from "uniqid";
// import {
//   MDBCard,
//   MDBCardImage,
//   MDBCardBody,
//   MDBCardTitle,
//   MDBCardText,
//   MDBRow,
//   MDBCol,
// } from "mdb-react-ui-kit";

export var projID = "";

function handleStartCreating() {
  projID = uniqid();
  // console.log(projID);
  localStorage.setItem("projID", projID);
}

function AppWeb() {
  return (
    <div>
      <nav className="navbar navbar-light bg-dark">
        <Typography
          variant="h4"
          component="div"
          style={{ color: "white", display: "flex", flexDirection: "row" }}
        >
          <div style={{ display: "inline-block" }}>𝔸𝕡𝕡𝕎𝕖𝕓</div>
          <div
            style={{
              display: "inline-block",
              fontSize: "17px",
              margin: "15px",
            }}
          >
            𝕗𝕣𝕠𝕞 𝕆𝕞𝕟é𝕍𝕦
          </div>
          {localStorage.getItem("user") ? (
            <></>
          ) : (
            <div
              style={{
                height: "100%",
                fontSize: "13px",
                marginTop: "12px",
              }}
            >
              (You'll Need to Login First)
            </div>
          )}
        </Typography>

        {localStorage.getItem("user") ? (
          <button
            onClick={handleStartCreating}
            className="btn btn-outline-light"
            style={{ width: "20%", backgroundColor: "yellow" }}
          >
            <Link to="/appwebcreate">Start Creating</Link>
          </button>
        ) : (
          <button
            onClick={handleStartCreating}
            className="btn btn-outline-light"
            style={{ width: "20%", backgroundColor: "yellow" }}
          >
            <Link to="/">Go to Login Page</Link>
          </button>
        )}
      </nav>
      <div
        style={{
          // backgroundImage: `url(${Create})`,
          height: "100%",
        }}
      >
        <p
          style={{
            color: "black",
            textAlign: "center",
            fontSize: "14pt",
            justifyContent: "center",
            paddingRight: "150pt",
            paddingLeft: "150pt",
            marginTop: "4%",
          }}
        >
          {" "}
          AppWeb from OmniVue is a simple, user-friendly application that can be
          used to create beautiful webpages and forms without the knowledge of
          coding, using simple and intuitive drag-and-drop methods. It enables
          the user to place elements in any way he/she desires just by dragging
          his/her mouse to that location. The elements are provided substantial
          customizability so that each user can customize their page according
          to their own taste. The user can create customized survey forms in
          minutes, and doesn't have to worry about the backend and data-storage,
          as the application takes care of it by itself.{" "}
        </p>
        <p
          style={{
            color: "black",
            textAlign: "center",
            fontSize: "14pt",
            justifyContent: "center",
            paddingRight: "150pt",
            paddingLeft: "150pt",
            marginTop: "4%",
          }}
        >
          {" "}
          AppWeb is a part of a large array of products offered by OmniVue.{" "}
        </p>
        {/* <div>
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, marginTop: "2%", marginLeft: "2%" }}
          >
            𝐏𝐫𝐞𝐯𝐢𝐨𝐮𝐬 𝐏𝐫𝐨𝐣𝐞𝐜𝐭𝐬
          </Typography>
        </div>

        <MDBRow
          className="row-cols-1 row-cols-md-3 g-4"
          style={{
            marginLeft: "2%",
            marginRight: "2%",
            width: "95%",
            height: "95%",
            marginTop: "2%",
          }}
        >
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src="https://mdbootstrap.com/img/new/standard/city/041.webp"
                alt="..."
                position="top"
                height="230px"
              />
              <MDBCardBody>
                <MDBCardTitle>Card title</MDBCardTitle>
                <MDBCardText>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src="https://mdbootstrap.com/img/new/standard/city/042.webp"
                alt="..."
                position="top"
                height="230px"
              />
              <MDBCardBody>
                <MDBCardTitle>Card title</MDBCardTitle>
                <MDBCardText>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBCardImage
                src="https://mdbootstrap.com/img/new/standard/city/043.webp"
                alt="..."
                position="top"
                height="230px"
              />
              <MDBCardBody>
                <MDBCardTitle>Card title</MDBCardTitle>
                <MDBCardText>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow> */}
        <button
          onClick={handleStartCreating}
          className="btn btn-outline-light"
          style={{
            marginLeft: "40%",
            marginTop: "10%",
            width: "20%",
            backgroundColor: "yellow",
          }}
        >
          <Link to="/">Go to Home Page</Link>
        </button>
        <footer
          className="page-footer font-small bg-dark"
          style={{ position: "fixed", left: "0", bottom: "0", width: "100%" }}
        >
          <div
            className="footer-copyright text-center py-3"
            style={{ color: "white" }}
          >
            © 2022 Copyright: OmniVue
          </div>
        </footer>
      </div>
    </div>
  );
}
export default AppWeb;
