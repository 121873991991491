import React from 'react'

const NonDraggableMap = (props) => {
    const style={
        position: "absolute",
        top: `${props.data.positionY + 70}px`,
        left: `${props.data.positionX}px`,
        height: `${props.data.height}`,
        width: `${props.data.width}`,
    }
  return (
    <iframe src={props.data.extraData[0].Iframe} title='Map' style={style}></iframe>
  )
}

export default NonDraggableMap