import React from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Rnd } from "react-rnd";
import { quantize } from "../QUANTIZATION/quantization";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DraggableEmailInput = (props) => {
    const [width, setWidth] = useState(props.dataEntry.width);
    const [height, setHeight] = useState(props.dataEntry.height);
    const [position, setPosition] = useState({
      x: props.dataEntry.positionX,
      y: props.dataEntry.positionY,
    });
  
  
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div>
        <Rnd
          position={{ x: position.x, y: position.y }}
          onDragStop={(e, d) => {
            const newDims = quantize(d.x, d.y);
            setPosition(newDims);
            props.refreshDATA(props.dataEntry.elementId,props.dataEntry.elementCode, newDims.x, newDims.y, height, width,[],localStorage.getItem("currPage"));
          }}
        >
          <input type="email" style={{ lineHeight: height, width: width }}></input>
  
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{borderRadius:"100%"}}
            disableRipple={true}
          >
            <MoreVertIcon/>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
              <input
                onChange={(e) => {
                  setWidth(e.target.value);
                  props.refreshDATA(props.dataEntry.elementId,props.dataEntry.elementCode, position.x, position.y, height, e.target.value,[],localStorage.getItem("currPage"));
                }}
                value={width}
              ></input>
            </MenuItem>
            <MenuItem>
              <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
              <input
                onChange={(e) => {
                  setHeight(e.target.value);
                  props.refreshDATA(props.dataEntry.elementId,props.dataEntry.elementCode, position.x, position.y, e.target.value, width,[],localStorage.getItem("currPage"));
                }}
                value={height}
              ></input>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                props.removeElementFromDATA(props.dataEntry.elementId);
              }}
            >
              Remove
            </MenuItem>
          </Menu>
        </Rnd>
      </div>
    );
}

export default DraggableEmailInput