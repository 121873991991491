import React from "react";

const NonDraggableHeader = (props) => {
  return (
    <div>
      <nav
        className="navbar navbar-light"
        style={{ backgroundColor: props.data.extraData[0].Color, borderRadius: "2px" }}
      >
        <h3 style={{ color: "white", fontFamily: "verdana" }}>{props.data.extraData[0].Content}</h3>
      </nav>
    </div>
  );
};

export default NonDraggableHeader;
