import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function RouteMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ROOT,setROOT] = React.useState(props.root);
  const [newPage, setNewPage] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "inline", marginLeft: "5px" }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Pages
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* compulsory menuitem */}
        <MenuItem
          onClick={() => {
            localStorage.setItem("currPage", "home");
            handleClose();
          }}
          disableRipple
        >
          <Link to={`/${ROOT}`}>Home</Link>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {
          props.extraPagesProp.map((extraPage, idx) => {
              if(extraPage!==""){
                   return (
                <MenuItem
                style={{display:"block"}}
                key={idx}
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.setItem("currPage", extraPage);
                    handleClose();
                  }}
                  disableRipple
                >
                  <Link to={`/${ROOT}/${extraPage}`}>{extraPage}</Link>
                </MenuItem>
            );
            }else{
                return null;
            }
           
          })
        }

        <MenuItem disabled={props.hidden} onKeyDown={(e) => e.stopPropagation()} disableRipple>
          <input
            type="text"
            value={newPage}
            onChange={(e) => {
              setNewPage(e.target.value);
            }}
            placeholder="Add New Page"
            style={{ display: "inline", margin: "10px" }}
          />
          <Button
            onClick={() => {
              props.addExtraPage(newPage);
            }}
          >
            Add
          </Button>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            localStorage.setItem("currPage", "contacts");
          }}
          disableRipple
        >
          <Link to={`/${ROOT}/contacts`}>Contacts</Link>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            localStorage.setItem("currPage", "contacts2");
          }}
          disableRipple
        >
          <Link to={`/${ROOT}/contacts2`}>Contacts2</Link>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            localStorage.setItem("currPage", "contacts3");
          }}
          disableRipple
        >
          <Link to={`/${ROOT}/contacts3`}>Contacts3</Link>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            localStorage.setItem("currPage", "contacts4");
          }}
          disableRipple
        >
          <Link to={`/${ROOT}/contacts4`}>Contacts4</Link>
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
}
