import React from 'react'

const NonDraggableTelephoneInput = (props) => {
    const style={
        position: "absolute",
        top: `${props.data.positionY+ 70}px`,
        left: `${props.data.positionX}px`,
        height: `${props.data.height}`,
        width: `${props.data.width}`
    }
  return (
        <input type="tel" placeholder='9876543210' pattern='[0-9]{10}' style={style}></input>
  )
}

export default NonDraggableTelephoneInput