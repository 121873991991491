import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';

const NonDraggableFacebook=(props)=> {
    const style={
        position: "absolute",
        top: `${props.data.positionY+70}px`,
        left: `${props.data.positionX}px`,
        height: `${props.data.height}`,
        width: `${props.data.width}`
    }
  return (
    <div style={style}>
            <a href={props.data.extraData[0].facebook}><FacebookIcon fontSize='large'/></a>
    </div>
  )
}

export default NonDraggableFacebook