import React from 'react'

const NonDraggableHeading = (props) => {
    const style={
        position: "absolute",
        top: `${props.data.positionY+ 70}px`,
        left: `${props.data.positionX}px`,
        height: `${props.data.height}`,
        width: `${props.data.width}`
    }
  return (
    <h1 style={style}>{props.data.extraData[0].Content}</h1>
  )
}

export default NonDraggableHeading