import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Rnd } from "react-rnd";
import { quantize } from "../QUANTIZATION/quantization";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./DraggableTable.css";

const DraggableTable = (props) => {
  const [width, setWidth] = useState(props.dataEntry.width);
  const [height, setHeight] = useState(props.dataEntry.height);
  const [position, setPosition] = useState({
    x: props.dataEntry.positionX,
    y: props.dataEntry.positionY,
  });
  const [numRows, setNumRows] = useState(props.dataEntry.extraData[0].tableRows);
  const [numCols, setnumCols] = useState(props.dataEntry.extraData[0].tableCols);


  const [tableData, setTableData] = useState(props.dataEntry.extraData[0].tDATA);

  var utilityArrayForRows = useRef([]);
  var utilityArrayForCols=useRef([]);

  useEffect(()=>{
    utilityArrayForCols.current = [];
    utilityArrayForRows.current = [];
    for(var i=0; i<numRows; i++){
        utilityArrayForRows.current.push(0);
    }
    for(var j=0; j<numCols; j++){
        utilityArrayForCols.current.push(0);
    }
  },[numRows, numCols]);

  console.log(utilityArrayForCols.current, utilityArrayForRows.current)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTableInputChange = (e)=>{
    e.preventDefault();
    var id = e.target.id;
    var value = e.target.value;
    setTableData((prev)=>{
      prev[id] =value;
      return prev;
    })
    console.log("New Table Data : ", tableData);

    props.refreshDATA(
      props.dataEntry.elementId,
      props.dataEntry.elementCode,
      position.x,
      position.y,
      height,
      e.target.value,
      [{tableCols: numCols, tableRows: numRows, tDATA: tableData}],
      localStorage.getItem("currPage")
    );
  };


  // const handleTableChange = (e)=>{
  //   e.preventDefault();
  //   setTableData(e.target);
  //   console.log(tableData);
  // }



  return (
    <div>
      <Rnd
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          const newDims = quantize(d.x, d.y);
          setPosition(newDims);
          props.refreshDATA(
            props.dataEntry.elementId,
            props.dataEntry.elementCode,
            newDims.x,
            newDims.y,
            height,
            width,
            props.dataEntry.extraData,
            localStorage.getItem("currPage")
          );
        }}
      >
        <table>
        <tbody>
        <tr>
            {utilityArrayForCols.current.map((value, index) => (
              <th key={index} >
                <input id={`heading-${index}`} value={tableData[`heading-${index}`]} onChange={handleTableInputChange} key={index} />
              </th>
            ))}
          </tr>
          {utilityArrayForRows.current.map((value, idxr) => {
            return (<tr key = {idxr}>
              {utilityArrayForCols.current.map((value, idxc) => {
                return (<td>
                  <input id={`entry-row-${idxr}-col-${idxc}`} value={tableData[`entry-row-${idxr}-col-${idxc}`]} onChange={handleTableInputChange} key={`${idxr}-${idxc}`} />
                </td>)
              })}
            </tr>);
          })}
        </tbody>
        </table>

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{ borderRadius: "100%" }}
          disableRipple={true}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem>
            <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
            <input
              onChange={(e) => {
                setWidth(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  e.target.value,
                  props.dataEntry.extraData,
                  localStorage.getItem("currPage")
                );
              }}
              value={width}
            ></input>
          </MenuItem>
          <MenuItem>
            <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
            <input
              onChange={(e) => {
                setHeight(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  e.target.value,
                  width,
                  props.dataEntry.extraData,
                  localStorage.getItem("currPage")
                );
              }}
              value={height}
            ></input>
          </MenuItem>

          <MenuItem>
            <h4 style={{ margin: "3px" }}>Columns: </h4>{" "}
            <input type="number"
              onChange={(e) => {
                setnumCols(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [{tableCols: e.target.value, tableRows:numRows, tDATA: tableData}],
                  localStorage.getItem("currPage")
                );
              }}
              value={numCols}
            ></input>
          </MenuItem>


          <MenuItem>
            <h4 style={{ margin: "3px" }}>Rows: </h4>{" "}
            <input type="number"
              onChange={(e) => {
                setNumRows(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [{tableCols: numCols, tableRows:e.target.value, tDATA: tableData}],
                  localStorage.getItem("currPage")
                );
              }}
              value={numRows}
            ></input>
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              props.removeElementFromDATA(props.dataEntry.elementId);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </Rnd>
    </div>
  );
};

export default DraggableTable;
