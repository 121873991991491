import React from "react";
import "./Palette.css";
import uniqid from "uniqid";
import { Link } from "react-router-dom";
import { rdb } from "../../firebase.js";
// import { projID } from "../AppWeb";
import { ref, set } from "firebase/database";

const Palette = (props) => {
  const AddAnInputToDATA = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "00",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [{}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddAHeadingToDATA = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "01",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [{ Content: "Sample Heading" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddAButtonToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "02",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{ Content: "Sample Button" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddAHeaderToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "03",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [{ Content: "Sample Heading", Color: "red" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddAParagraphToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "04",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [
            {
              Content: "This is a Sample Paragraph",
              Color: "red",
              Align: "left",
            },
          ],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddAnImageToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "05",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [{ imageURL: "" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddACheckBoxToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "06",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [{ checkBoxLabel: "Sample CheckBox" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddARadioInputToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "07",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [{ radioLabel: "Sample Radio Input", radioName: "name" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddADropdownListToData = () =>{
    props.setCanvasHeight((prev)=>{
      return prev+500;
    })
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "08",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [{dropdownLabel: "Sample Dropdown List", dropdownName: "dropdown", options:["option 1", "option 2"]}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }

  const AddARadioGroupToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "09",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [
            {
              groupSize: 1,
              radioLabels: ["Sample Radio Input"],
              radioGroupName: "name",
            },
          ],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddAMapToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "10",
          height: "400px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{ Iframe: "" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddAnIPToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "11",
          height: "100px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{ ip: "" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };
  const AddADateAndTimeInputToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "12",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{ dateTime: "2017-06-01T08:30" }],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddAnEmailInputToData = () => {
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "13",
          height: "30px",
          width: "800px",
          positionX: 0,
          positionY: 0,
          extraData: [{}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  };

  const AddATelephoneInputToData = () =>{
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "14",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }

  const AddATableToData = ()=>{
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "15",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{tableCols:5, tableRows:5, tDATA:{}}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }

  const AddAnInstaLinkToData = ()=>{
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "16",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{instagram:""}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }

  const AddAFacebookLinkToData = () =>{
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "17",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{facebook:""}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }


  const AddALinkedinLinkToData = ()=>{
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "18",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{linkedin:""}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }

  const AddATwitterLinkToData = ()=>{
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "19",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{twitter:""}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }


  const AddAYoutubeLinkToData = ()=>{
    props.setCanvasHeight((prev) => {
      return prev + 500;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "20",
          height: "30px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{youtube:""}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }


  const AddACarouselToData = ()=>{
    props.setCanvasHeight((prev) => {
      return prev + 1000;
    });
    props.setDATA((prev) => {
      prev = [
        ...prev,
        {
          elementId: uniqid(),
          elementCode: "21",
          height: "300px",
          width: "400px",
          positionX: 0,
          positionY: 0,
          extraData: [{link1:"https://wallpapercave.com/dwp1x/wp9389330.jpg", link2:"", link3:"", link4:"", link5:""}],
          page: localStorage.getItem("currPage")
        },
      ];
      return prev;
    });
  }


  const publishrData = () => {
    const datatosend = props.DATA;
    const allTemplates = props.allTemplates;
    if (datatosend.length === 0) {
      alert("You need to make a website before publishing it.");
    } else {
      const PROJID = localStorage.getItem("projID");
      const datetime = new Date();
      const completeData = {
        pages: props.extraPagesProp,
        projID: PROJID,
        projectName: "SampleProject", //Think about how to take input for project name
        projectLastUpdatedAt: datetime.toISOString(),
        projectLastUpdatedBy: JSON.parse(localStorage.getItem("user")).uid,
        TEMPLATES: [...allTemplates, datatosend],
      };
      set(ref(rdb, "projects/" + PROJID), completeData);
    }
  };

  return (
    <>
      <div className="Publish-button">
        <button className="publish-button" onClick={publishrData}>
          <Link className="publish-text" to="/publish">
            Publish
          </Link>
        </button>
      </div>
      <div className="Elements-list">
        <button className="palette-button" onClick={AddAnInputToDATA}>
          Input
        </button>
        <button className="palette-button" onClick={AddAHeadingToDATA}>
          Heading
        </button>
        <button className="palette-button" onClick={AddAButtonToData}>
          Button
        </button>
        <button className="palette-button" onClick={AddAHeaderToData}>
          Header
        </button>
        <button className="palette-button" onClick={AddAParagraphToData}>
          Paragraph
        </button>
        <button className="palette-button" onClick={AddAnImageToData}>
          Image
        </button>
        <button className="palette-button" onClick={AddACheckBoxToData}>
          CheckBox
        </button>
        <button className="palette-button" onClick={AddARadioInputToData}>
          Radio Input
        </button>
        <button className="palette-button" onClick={AddADropdownListToData}>
          Dropdown List
        </button>
        <button className="palette-button" onClick={AddARadioGroupToData}>
          Radio Group
        </button>
        <button className="palette-button" onClick={AddAMapToData}>
          IFrame Map
        </button>
        <button className="palette-button" onClick={AddAnIPToData}>
          IP Address
        </button>
        <button className="palette-button" onClick={AddADateAndTimeInputToData}>
          Date And Time Input
        </button>
        <button className="palette-button" onClick={AddAnEmailInputToData}>
          Email Input
        </button>
        <button className="palette-button" onClick={AddATelephoneInputToData}>
          Telephone Input
        </button>
        <button className="palette-button" onClick={AddATableToData}>
          Table
        </button>
        <button className="palette-button" onClick={AddAnInstaLinkToData}>
          Instagram Link
        </button>
        <button className="palette-button" onClick={AddAFacebookLinkToData}>
          Facebook Link
        </button>
        <button className="palette-button" onClick={AddALinkedinLinkToData}>
          LinkedIn Link
        </button>
        <button className="palette-button" onClick={AddATwitterLinkToData}>
          Twitter Link
        </button>
        <button className="palette-button" onClick={AddAYoutubeLinkToData}>
          YouTube Link
        </button>
        <button className="palette-button" onClick={AddACarouselToData}>
          Carousel 
        </button>
      </div>
    </>
  );
};

export default Palette;
