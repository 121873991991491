import React from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Rnd } from "react-rnd";
import { quantize } from "../QUANTIZATION/quantization";
import MoreVertIcon from "@mui/icons-material/MoreVert";

//utility function
function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

const DraggableRadioGroup = (props) => {
  const [groupSize, setGroupSize] = React.useState(
    props.dataEntry.extraData[0].groupSize
  );
  const [labels, setLabels] = React.useState(
    props.dataEntry.extraData[0].radioLabels
  );
  const [groupName, setGroupName] = React.useState(
    props.dataEntry.extraData[0].radioGroupName
  );
  const [width, setWidth] = useState(props.dataEntry.width);
  const [height, setHeight] = useState(props.dataEntry.height);
  const [position, setPosition] = useState({
    x: props.dataEntry.positionX,
    y: props.dataEntry.positionY,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Rnd
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          const newDims = quantize(d.x, d.y);
          setPosition(newDims);
          props.refreshDATA(
            props.dataEntry.elementId,
            props.dataEntry.elementCode,
            newDims.x,
            newDims.y,
            height,
            width,
            [...props.dataEntry.extraData],
            localStorage.getItem("currPage")
          );
        }}
        style={{
          border: "2px solid black",
          padding: "10px",
          borderRadius: "2px",
        }}
      >
        {labels.map((label, idx) => {
          return (
            <div key={idx}>
              <input
                value={camelize(label.trim()).replace(/ /g, "")}
                type="radio"
                name={groupName}
              ></input>
              <label htmlFor={camelize(label.trim()).replace(/ /g, "")}>
                {label}
              </label>
            </div>
          );
        })}

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{ marginLeft: "50%" }}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Group Size: </h4>{" "}
            <input
              type="number"
              onChange={(e) => {
                setGroupSize(e.target.value);
                if (e.target.value < labels.length) {
                  console.log("inside if");
                  setLabels((prev) => {
                    return prev.slice(0, prev.length-1);
                  });
                } else if (e.target.value > labels.length) {
                  console.log("inside else");
                  setLabels((prev) => {
                    return [...prev, "sample label"];
                  });
                }
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [
                    {
                      groupSize: groupSize,
                      radioLabels: labels,
                      radioGroupName: groupName,
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={groupSize}
            ></input>
          </MenuItem>

          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Group Name: </h4>{" "}
            <input
              onChange={(e) => {
                setGroupName(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [
                    {
                      groupSize: groupSize,
                      radioLabels: labels,
                      radioGroupName: groupName,
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={groupName}
            ></input>
          </MenuItem>

          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            {labels.map((label, idx) => {
              return (
                <div style={{ display: "block" }} key={idx}>
                  <h4 style={{ margin: "3px" }}>Label: </h4>{" "}
                  <input
                    onChange={(e) => {
                      e.preventDefault();
                      setLabels((prev) => {
                        prev[idx] = e.target.value;
                        return prev;
                      });
                      console.log(labels);
                      props.refreshDATA(
                        props.dataEntry.elementId,
                        props.dataEntry.elementCode,
                        position.x,
                        position.y,
                        height,
                        e.target.value,
                        [
                          {
                            groupSize: groupSize,
                            radioLabels: labels,
                            radioGroupName: groupName,
                          },
                        ],
                        localStorage.getItem("currPage")
                      );
                    }}
                    value={label}
                  ></input>
                </div>
              );
            })}
          </MenuItem>

          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
            <input
              onChange={(e) => {
                e.preventDefault();
                setWidth(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  e.target.value,
                  [...props.dataEntry.extraData],
                  localStorage.getItem("currPage")
                );
              }}
              value={width}
            ></input>
          </MenuItem>
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
            <input
              onChange={(e) => {
                setHeight(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  e.target.value,
                  width,
                  [...props.dataEntry.extraData],
                  localStorage.getItem("currPage")
                );
              }}
              value={height}
            ></input>
          </MenuItem>

          <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => {
              props.removeElementFromDATA(props.dataEntry.elementId);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </Rnd>
    </div>
  );
};

export default DraggableRadioGroup;
