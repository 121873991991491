import { Button } from "@mui/material";
import React from "react";
import "./Responses.css";
import { rdb } from "../../firebase";
import { ref, onValue } from "firebase/database";

const Responses = () => {
  const projID = window.location.href.split("/")[4];
  const [userEmail, setUserEmail] = React.useState("");
  const [authorized, setAuthorized] = React.useState(null);
  const [fetchedEmail, setFetchedEmail] = React.useState("");
  //   const [fetchedData, setFetchedData] = React.useState({});
  //   var data = {};
  const dataRef = React.useRef();

  React.useEffect(() => {
    const reference = ref(rdb, "projects/" + projID + "/projectLastUpdatedBy");
    onValue(reference, (snapshot) => {
      const data = snapshot.val();
      const userreference = ref(rdb, "users/" + data + "/email");
      onValue(userreference, (snapshot2) => {
        const data2 = snapshot2.val();
        setFetchedEmail(data2);
      });
    });
  }, [projID]);

  React.useEffect(() => {
    const datareference = ref(rdb, "DATA/" + projID);
    onValue(datareference, (snapshot) => {
      const data = snapshot.val();
      dataRef.current = data;
    });
  }, [projID]);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (userEmail === fetchedEmail) {
      setAuthorized(true);
    } else {
      setAuthorized(false);
    }
  };

  function download(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
   }
   
   function onDownload(jsondata){
    download(JSON.stringify(jsondata), "responses.json", "text/plain");
   }

  return (
    <div className="responses__fullpage">
      <label htmlFor="response__email">Plese Enter your E-mail : </label>
      <input
        autoComplete="off"
        type="email"
        value={userEmail}
        name="response__email"
        id="response__email"
        onChange={(e) => {
          setUserEmail(e.target.value);
        }}
      />
      <Button onClick={handleEmailSubmit}>Submit</Button>

      <br />
      <hr />

      {authorized ? (
        <div className="responsesSection">
        <Button onClick={()=>{onDownload(dataRef.current)}} type="button">Download as File</Button>
        <table style={{border:"1px solid black", width:"100%"}}>
          {Object.keys(dataRef.current).map((key, index) => {
            return (
              <tr key={index}>
                {dataRef.current[key].map((item, idx) => {
                  if(idx>2) return <td style={{border:"1px solid black"}} key={idx}>{item}</td>;
                  else return <></>
                })}
              </tr>
            );
          })}
        </table>
        </div>
      ) : (
        <p>Please Submit the Correct Email to view the Responses</p>
      )}
    </div>
  );
};

export default Responses;
