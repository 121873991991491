import React from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Rnd } from "react-rnd";
import { storage } from "../../firebase";
import { ref, uploadBytes,getDownloadURL   } from "firebase/storage";
import { quantize } from "../QUANTIZATION/quantization";
import MoreVertIcon from '@mui/icons-material/MoreVert';


const DraggableImage = (props) => {
  const [width, setWidth] = useState(props.dataEntry.width);
  const [height, setHeight] = useState(props.dataEntry.height);
  //   const [color, setColor] = useState(props.dataEntry.Color);
  //  const [align, setAlign] = useState(props.dataEntry.Align);
//   const [selectedImage, setSelectedImage] = useState();
  //  const [content, setContent] = useState(props.dataEntry.extraData[0].Content);
  const [position, setPosition] = useState({
    x: props.dataEntry.positionX,
    y: props.dataEntry.positionY,
  });
  const [URL, setURL] = useState(props.dataEntry.extraData[0].imageURL);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const imageChange = (e) => {
  //     console.log("Rutvik efegrg")
  //     if (e.target.files && e.target.files.length > 0) {
  //       setSelectedImage(e.target.files[0]);
  //     }
  //   };

  const FilevalidationAndUpload = (e) => {
    e.preventDefault();
    const fi = document.getElementById("file");

    if (fi.files.length > 0) {
      // Check if any file is selected
      for (var i = 0; i <= fi.files.length - 1; i++) {
        const fsize = fi.files.item(i).size;
        //console.log(fsize);
        const file = Math.round(fsize / 1024);
        console.log("filesize : ", file);
        if (file >= 200) {
          alert("Image too large, please select a image less than 200KB");
        } else {
          if (e.target.files && e.target.files.length > 0) {
            const imagesRef = ref(storage, `images/${props.dataEntry.elementId}`);
            uploadBytes(imagesRef, e.target.files[0]).then((snapshot) => {
                
                getDownloadURL(imagesRef)
                .then((url)=>{
                    setURL(url);
                    console.log(url);
                    props.refreshDATA(
                        props.dataEntry.elementId,
                        props.dataEntry.elementCode,
                        position.x,
                        position.y,
                        height,
                        width,
                        [{imageURL:url}],
                        localStorage.getItem("currPage")
                      );
                      console.log('Uploaded a your image!');
                })
              });
          }
        }
      }
    }
  };

  return (
    <div>
      <Rnd
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          const newDims = quantize(d.x, d.y);
          setPosition(newDims);
          props.refreshDATA(
            props.dataEntry.elementId,
            props.dataEntry.elementCode,
            newDims.x,
            newDims.y,
            height,
            width,
            [{imageURL:URL}],
            localStorage.getItem("currPage")
          );
        }}
      >
          <div
            style={{ marginTop: 50, display: "flex", flexDirection: "column" }}
          >
            <img
              src={URL}
              style={{ width: width, height: height }}
              alt="images"
            />
          </div>
        

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem>
            <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
            <input
              onChange={(e) => {
                e.preventDefault();
                setWidth(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  e.target.value,
                  [...props.dataEntry.extraData],
                  localStorage.getItem("currPage")
                );
              }}
              value={width}
            ></input>
          </MenuItem>
          <MenuItem>
            <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
            <input
              onChange={(e) => {
                setHeight(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  e.target.value,
                  width,
                  [...props.dataEntry.extraData],
                  localStorage.getItem("currPage")
                );
              }}
              value={height}
            ></input>
          </MenuItem>

          <MenuItem>
            <h4 style={{ margin: "3px" }}>Select: </h4>{" "}
            <input
              accept="image/*"
              type="file"
              id="file"
              onChange={FilevalidationAndUpload}
              required
            />
          </MenuItem>
          <p style={{ margin: "3px", color: "red" }}>
            (Only Images allowed And Image size less than 200 KB)
          </p>

          <MenuItem
            onClick={(e) => {
              props.removeElementFromDATA(props.dataEntry.elementId);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </Rnd>
    </div>
  );
};

export default DraggableImage;
