import React from "react";

const NonDraggableParagraph = (props) => {
  const style = {
    position: "absolute",
    top: `${props.data.positionY+ 70}px`,
    left: `${props.data.positionX}px`,
    lineheight: `${props.data.height}`,
    width: `${props.data.width}`,
    textAlign: `${props.data.extraData[0].Align}`,
    backgroundColor: `${props.data.extraData[0].Color}`,
    marginBotton: "50px"
  };
  return (
    <p
      style={style}
    >
      {props.data.extraData[0].Content}
    </p>
  );
};

export default NonDraggableParagraph;
