import React from "react";

//utility function
function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

const NonDraggableRadioGroup = (props) => {
  const style = {
    position: "absolute",
    top: `${props.data.positionY + 70}px`,
    left: `${props.data.positionX}px`,
    height: `${props.data.height}`,
    width: `${props.data.width}`
  };

  return (
    <div style={style}>
      {props.data.extraData[0].radioLabels.map((label, idx) => {
        return (
          <div key={idx}>
            <input
              type="radio"
              name={props.data.extraData[0].radioGroupName}
              value={camelize(label.trim()).replace(/ /g, "")}
            ></input>
            <label htmlFor={camelize(label.trim()).replace(/ /g, "")}>
              {label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default NonDraggableRadioGroup;
