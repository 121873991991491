import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  // MDBFooter,
} from "mdb-react-ui-kit";
//import { MDBCard, MDBCardTitle, MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdbreact';

import React, { useEffect } from "react";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
//  import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { CardActionArea, CardActions } from "@mui/material";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import "./Patang.css";
import history from "./history";
import footer from "./AppWeb/Images/footer.jpg";
import publish from "./AppWeb/Images/publish.jpg";
import { Navbar, Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import { getAuth, signInWithPopup, signOut } from "firebase/auth";
import { provider } from "./firebase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ref, set } from "firebase/database";
import { rdb } from "./firebase";
import ov from "./OV.jpeg";
import maps2 from "./maps2.jpeg"; 
import omnevu2 from "./omnevu2.jpeg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#000000",
    },
  },
});

// const imgAddr = "https://wallpapercave.com/uwp/uwp1215842.png";

const Patang = () => {
  const [user, setUser] = React.useState();
  console.log(user);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  //utility functions for menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //utility functions over

  const googleLogin = () => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log(user);
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        set(ref(rdb, 'users/' + user.uid), {
          email: user.email,
          displayName: user.displayName,
          authorized: true
        });
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(`${errorCode} : ${errorMessage}`);
      });
  };

  const googleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem("user");
        setUser(null);
      })
      .catch((error) => {
        // An error happened.
        alert("An Error Occured, couldn't sign out.");
      });
  };
  
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Navbar bg="dark" expand={false} variant="dark">
          <Container fluid>
            {/* <Navbar.Brand> */}
            <img
                src={omnevu2}
                alt="canvas-emblem"
                className=""
                style={{ margin: "0px", height:"40px", width:"130px" }}
              ></img>
            {/* </Navbar.Brand> */}

            {!user ? (
              <Button onClick={googleLogin}>Login with Google</Button>
            ) : (
              <>
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    {user.displayName}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={googleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              </>
            )}

            {/* <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel">
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#action1">About</Nav.Link>
                  <Nav.Link href="#action2">Contact</Nav.Link>
                  <Nav.Link href="#action3">Login</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas> */}
          </Container>
        </Navbar>
        <Grid
          container
          spacing={1}
          justify="center"
          style={{ width: "auto", height: "auto", marginTop: "5px" }}
        >
          <Grid item sm={6} align="center">
            <MDBCard
              style={{ width: "80%", height: "80%", borderRadius: "50" }}
              className="shadow-lg p-3 mb-5 bg-white rounded"
            >
              <MDBCardImage
                src={footer}
                position="top"
                alt="..."
                height="60%"
              />
              <MDBCardBody>
                {/* <MDBCardTitle style={{color:'blue',fontsize:'10pt'}}>🅰🅿🅿 🆆🅴🅱</MDBCardTitle> */}
                <Typography
                  variant="h3"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{ fontFamily: "Times New Roman" }}
                >
                  NOCODE
                </Typography>
                <MDBCardText>
                  We provide you tools to build your world on internet and
                  explore your customer there by publishing it online and
                  tracking your website status just by some simple click and
                  selecting some bundles already provided you can make a website
                  like Pro.
                </MDBCardText>

                <MDBBtn
                  outline
                  size="small"
                  fullwidth="true"
                  color="dark"
                  onClick={() => {
                    if (!user) {
                      alert("PLEASE LOG IN FIRST.");
                    } else {
                      history.push("/AppWeb");
                    }
                  }}
                >
                  Click Here
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item sm={6} align="center">
            <MDBCard
              style={{ width: "80%", height: "80%" }}
              className="shadow-lg p-3 mb-5 bg-white rounded"
            >
              <MDBCardImage
                src={publish}
                position="top"
                alt="..."
                height="60%"
              />
              <MDBCardBody>
                {/* <MDBCardTitle>Sur Whey</MDBCardTitle> */}
                <Typography
                  variant="h3"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{ fontFamily: "Times New Roman" }}
                >
                  NLP-CV
                </Typography>
                <MDBCardText>
                  We provide you tools to build your world on internet and
                  explore your customer there by publishing it online and
                  tracking your website status just by some simple click and
                  selecting some bundles already provided you can make a website
                  like Pro.{" "}
                </MDBCardText>
                <MDBBtn
                  outline
                  size="small"
                  fullwidth="true"
                  color="dark"
                  onClick={() => {
                    if (!user) {
                      alert("PLEASE LOG IN FIRST.");
                    } else {
                      history.push("/SurWhey");
                    }
                  }}
                >
                  Click Here
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item sm={6} md={12} lg={12} align="center">
            <MDBCard
              style={{ width: "80%", height: "80%", borderRadius: "50" }}
              className="shadow-lg p-3 mb-5 bg-white rounded"
            >
              <MDBCardImage
                src={maps2}
                position="top"
                alt="..."
                height="60%"
              />
              <MDBCardBody>
                {/* <MDBCardTitle style={{color:'blue',fontsize:'10pt'}}>🅰🅿🅿 🆆🅴🅱</MDBCardTitle> */}
                <Typography
                  variant="h3"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{ fontFamily: "Times New Roman" }}
                >
                  MAPS2
                </Typography>
                <MDBCardText>
                  We provide you tools to build your world on internet and
                  explore your customer there by publishing it online and
                  tracking your website status just by some simple click and
                  selecting some bundles already provided you can make a website
                  like Pro.
                </MDBCardText>

                <MDBBtn
                disabled="true"
                  outline
                  size="small"
                  fullwidth="true"
                  color="dark"
                  onClick={() => {
                    if (!user) {
                      alert("PLEASE LOG IN FIRST.");
                    } else {
                      window.location.href =
                      "https://text-detection-widget.web.app/";
                    }
                  }}
                >
                  Click Here
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          
          {/* <MDBFooter
            className="text-center text-white"
            style={{ backgroundColor: "#01090f" }}
          >
            <div
              className="text-center p-3"
              style={{ backgroundColor: "#01090f" }}
            >
              © 2022 Copyright: PaTanG All Rights Reserved
            </div>
          </MDBFooter> */}
        </Grid>
        <footer
          className="page-footer font-small bg-dark"
          style={{ marginTop: "8%" }}
        >
          <div
            className="footer-copyright text-center py-3"
            style={{ color: "white" }}
          >
            © 2022 Copyright: OmnéVu Team
          </div>
        </footer>
      </ThemeProvider>
    </div>
  );
};

export default Patang;
