import React from "react";

//utility
function createArray(length) {
  var arr = new Array(length || 0),
    i = length;

  if (arguments.length > 1) {
    var args = Array.prototype.slice.call(arguments, 1);
    while (i--) arr[length - 1 - i] = createArray.apply(this, args);
  }

  return arr;
}

const NonDraggableTable = (props) => {
  const style = {
    position: "absolute",
    top: `${props.data.positionY + 70}px`,
    left: `${props.data.positionX}px`,
    height: `${props.data.height}`,
    // width: `${200}px`,
    textAlign: "center"
  };

  const tableData = React.useRef([]);

  React.useState(() => {
    const data = props.data.extraData[0].tDATA;
    const numRows = parseInt(props.data.extraData[0].tableRows);
    const numCols = parseInt(props.data.extraData[0].tableCols);
    // for (var i = 0; i <= numRows + 1; i++) {
    //   var arrtoadd = [];
    //   for (var j = 0; j < numCols; j++) {
    //     arrtoadd.push("");
    //   }
    //   tableData.current.push(arrtoadd);
    // }
    tableData.current = createArray(numRows + 1, numCols);
    console.log("tableData before : ", tableData);
    for (var property in data) {
      if (property[0] === "h") {
        tableData.current[0][parseInt(property[property.length - 1])] =
          data[property];
      } else if (property[0] === "e") {
        tableData.current[parseInt(property[property.length - 7]) + 1][
          parseInt(property[property.length - 1])
        ] = data[property];
      }
    }
    console.log("TABLEDATA : ", tableData);
  }, []);

  return (
    <table style={style}>
    <tbody>
      {tableData.current.map((tableRow, rowIdx) => {
        if (rowIdx === 0) {
          return (
            <tr key={'headingRow'}>
              {tableRow.map((heading, hidx) => {
                return <th style={{padding:"10px",width:"300px"}} key={`heading-${hidx}`}>{heading}</th>;
              })}
            </tr>
          );
        } else {
          return (
            <tr key={`row-${rowIdx}`}>
              {tableRow.map((entry, eidx) => {
                return <td style={{padding:"10px",width:"300px"}} key={`entry-${eidx}`}>{entry}</td>;
              })}
            </tr>
          );
        }
      })}
    </tbody>
      
    </table>
  );
};

export default NonDraggableTable;
