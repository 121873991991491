import React from 'react'

const NonDraggableInput = (props) => {

    const style={
        position: "absolute",
        top: `${props.data.positionY+ 70}px`,
        left: `${props.data.positionX}px`,
        height: `${props.data.height}`,
        width: `${props.data.width}`
    }
  return (
        <input style={style}></input>
  )
}

export default NonDraggableInput