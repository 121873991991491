import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import AppWeb from "./AppWeb/AppWeb"
import Main from "./Patang";
import history from './history';
import DForms from "./DForms/DForms";
import SurWhey from "./SurWhey/SurWhey";
import PersistentDrawerRight from "./AppWeb/AppWebCreate";
import Publish from "./AppWeb/Publish/Publish";
import Deploy from "./AppWeb/Deploy/Deploy";
import Responses from "./AppWeb/Responses/Responses";


export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Main} />
                    <Route path="/AppWeb" component={AppWeb} />
                    <Route path="/DForms" component={DForms} />
                    <Route path="/SurWhey" component={SurWhey} />
                    <Route path="/appwebcreate" component={PersistentDrawerRight}/>
                    <Route path="/appwebcreate/:route" component={PersistentDrawerRight}/>
                    <Route path="/publish" component={Publish}/>
                    <Route path="/publish/:route" component={Publish}/>
                    <Route path="/deploy/:projID" component={Deploy}/>
                    <Route path="/responses/:projID" component={Responses}/>
                </Switch>
            </Router>
        )
    }
}
