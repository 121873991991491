import React from "react";
import { rdb } from "../../firebase";
import NonDraggableInput from "../NonDraggableInput/NonDraggableInput";
import NonDraggableHeading from "../NonDraggableHeading/NonDraggableHeading";
import NonDraggableButton from "../NonDraggableButton/NonDraggableButton";
import NonDraggableHeader from "../NonDraggableHeader/NonDraggableHeader";
import NonDraggableParagraph from "../NonDraggableParagraph/NonDraggableParagraph";
import NonDraggableImage from "../NonDraggableImage/NonDraggableImage";
import { ref, onValue, push, child, update } from "firebase/database";
import NonDraggableCheckBox from "../NonDraggableCheckBox/NonDraggableCheckBox";
import NonDraggableRadioInput from "../NonDraggableRadioInput/NonDraggableRadioInput";
import { AppBar, Button } from "@mui/material";
import logo from "../Images/logo.png";
import "./Deploy.css";
import { Typography } from "@mui/material";
import {Link} from "react-router-dom";

//MODAL IMPORTS
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import NonDraggableRadioGroup from "../NonDraggableRadioGroup/NonDraggableRadioGroup";
import NonDraggableMap from "../NonDraggableMap/NonDraggableMap";
import NonDraggabeIP from "../NonDraggableIP/NonDraggabeIP";
import NonDraggableDateAndTimeInput from "../NonDraggableDateAndTimeInput/NonDraggableDateAndTimeInput";
import NonDraggableEmailInput from "../NonDraggableEmailInput/NonDraggableEmailInput";
import NonDraggableTelephoneInput from "../NonDraggableTelephoneInput/NonDraggableTelephoneInput";
import RouteMenu from "../RouteMenu/ReouteMenu";
import NonDraggableTable from "../NonDraggableTable/NonDraggableTable";
import NonDraggableInstagram from "../NonDraggableInstagram/NonDraggableInstagram";
import NonDraggableFacebook from "../NonDraggableFacebook/NonDraggableFacebook";
import NonDraggableLinkedin from "../NonDraggableLinkedin/NonDraggableLinkedin";
import NonDraggableTwitter from "../NonDraggableTwitter/NonDraggableTwitter";
import NonDraggableYoutube from "../NonDraggableYoutube/NonDraggableYoutube";
import NonDraggableCarousel from "../NonDraggableCarousel/NonDraggableCarousel";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Deploy = () => {
  const [retrievedDATA, setRetrievedDATA] = React.useState([]);
  const [extraPages, setExtraPages] = React.useState([]);

  //MODAL CODE:
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //
  const addPage = (pagename)=>{
    setExtraPages((prev)=>{
      return [
        ...prev,
        pagename
      ]
    })
  }
  const projID_1 = window.location.href.split("/")[4];
  React.useEffect(() => {
    const starCountRef = ref(rdb, "projects/" + projID_1);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("DATA: ", data);
      setRetrievedDATA(data.TEMPLATES[data.TEMPLATES.length - 1]);
      setExtraPages(data.pages);
    });
  }, [projID_1]);

  const sendDATA = (arr) => {
    const newPostKey = push(child(ref(rdb), `DATA/${projID_1}`)).key;
    const updates = {};
    updates[`/DATA/${projID_1}/${newPostKey}`] = arr;

    update(ref(rdb), updates).then(() => {
      alert("Submitted");
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    const DATA_ARRAY = [];
    for (var i = 0; i < e.target.length; i++) {
      // if(e.target[i].checked !== undefined){
      //   DATA_ARRAY.push(e.target[i].checked);
      // }else{
      //   DATA_ARRAY.push(e.target[i].value);
      // }
      if(e.target[i].type==="radio" || e.target[i].type==="checkbox"){
        const sendObj = {
          value: e.target[i].value,
          checked: e.target[i].checked
        }
        // console.log(sendObj)
        DATA_ARRAY.push(JSON.stringify(sendObj));
      }
      else{
        // console.log(e.target[i].value)
        DATA_ARRAY.push(e.target[i].value);
      }
    }
    sendDATA(DATA_ARRAY);
  };

  return (
    <div className="deploy__canvas">
      {/* MODAL CODE */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Copy Link
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {window.location.href}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      {/* Modal CODE END */}

      <form onSubmit={handleFormSubmit} className="deploy__form">
        <AppBar className="deploy__Appbar" position="fixed">
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            <a
              style={{ margin: "10px", marginLeft: "30px" }}
              className="navbar-brand canvas-title"
              href="/"
            >
              <img
                src={logo}
                className="d-inline-block align-top canvas-emblem"
                alt="canvas-emblem"
              ></img>
            </a>
            <Button
              type="submit"
              style={{ color: "white", float: "right", margin: "10px 30px" }}
            >
              Submit
            </Button>

            {/* MODAL CODE */}
            <Button
              type="button"
              style={{ color: "white", float: "right", margin: "10px 30px" }}
              onClick={handleOpen}
            >
              Share
            </Button>
            {/* MODAL CODE END */}
            <RouteMenu root={`deploy/${projID_1}`} extraPagesProp={extraPages} hidden={true} addExtraPage={addPage}/>

            <Button
              type="button"
              style={{ color: "white", float: "right", margin: "10px 30px" }}
            >
              <Link className="Responses-text" to={`/responses/${projID_1}`}>Responses</Link>
            </Button>
          </Typography>
        </AppBar>
        {retrievedDATA.map((dataEntry) => {
          if (dataEntry.elementCode === "00"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableInput key={dataEntry.elementId} data={dataEntry} />
            );
          } else if (dataEntry.elementCode === "01"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableHeading key={dataEntry.elementId} data={dataEntry} />
            );
          } else if (dataEntry.elementCode === "02"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableButton key={dataEntry.elementId} data={dataEntry} />
            );
          } else if (dataEntry.elementCode === "03"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableHeader key={dataEntry.elementId} data={dataEntry} />
            );
          } else if (dataEntry.elementCode === "04"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableParagraph
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          } else if (dataEntry.elementCode === "05"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableImage key={dataEntry.elementId} data={dataEntry} />
            );
          } else if (dataEntry.elementCode === "06"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableCheckBox
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          } else if (dataEntry.elementCode === "07"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableRadioInput
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "09"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableRadioGroup
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "10"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableMap
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "11"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggabeIP
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "12"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableDateAndTimeInput
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "13"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableEmailInput
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "14"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableTelephoneInput
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "15"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableTable
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "16"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableInstagram
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "17"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableFacebook
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "18"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableLinkedin
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "19"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableTwitter
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "20"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableYoutube
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          else if (dataEntry.elementCode === "21"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableCarousel
                key={dataEntry.elementId}
                data={dataEntry}
              />
            );
          }
          return 0;
        })}
        <footer
          className="page-footer font-small"
          style={{ position: "fixed", left: "0", bottom: "0", width: "100%", backgroundColor:"white" }}
        >
          <div
            className="footer-copyright text-center py-3"
            style={{ color: "black" }}
          >
            © 2022 Copyright: OmniVue
          </div>
        </footer>
      </form>
      
    </div>
  );
};

export default Deploy;
