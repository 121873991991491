import React from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Rnd } from "react-rnd";
import { quantize } from "../QUANTIZATION/quantization";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DraggableParagraph = (props) => {
  const [width, setWidth] = useState(props.dataEntry.width);
  const [height, setHeight] = useState(props.dataEntry.height);
  const [color, setColor] = useState(props.dataEntry.extraData[0].Color);
  const [align, setAlign] = useState(props.dataEntry.extraData[0].Align);
  const [content, setContent] = useState(props.dataEntry.extraData[0].Content);
  const [position, setPosition] = useState({
    x: props.dataEntry.positionX,
    y: props.dataEntry.positionY,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Rnd
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          const newDims = quantize(d.x, d.y);
          setPosition(newDims);
          props.refreshDATA(
            props.dataEntry.elementId,
            props.dataEntry.elementCode,
            newDims.x,
            newDims.y,
            height,
            width,
            [...props.dataEntry.extraData],
            localStorage.getItem("currPage")
          );
        }}
      >
        <p
          style={{
            lineHeight: height,
            width: width,
            textAlign: align,
            backgroundColor: color,
          }}
        >
          {content}
        </p>

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Content: </h4>{" "}
            <input
              onChange={(e) => {
                setContent(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [{ Content: e.target.value, Color: color, Align: align }],
                  localStorage.getItem("currPage")
                );
              }}
              value={content}
            ></input>
          </MenuItem>
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Color: </h4>{" "}
            <input
              type="color"
              id="favcolor"
              name="favcolor"
              style={{ width: "150pt" }}
              onChange={(e) => {
                setColor(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [
                    {
                      Content: content,
                      Color: e.target.value,
                      Align: align,
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={color}
            ></input>
          </MenuItem>

          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
            <input
              onChange={(e) => {
                e.preventDefault();
                setWidth(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  e.target.value,
                  [...props.dataEntry.extraData],
                  localStorage.getItem("currPage")
                );
              }}
              value={width}
            ></input>
          </MenuItem>
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
            <input
              onChange={(e) => {
                setHeight(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  e.target.value,
                  width,
                  [...props.dataEntry.extraData],
                  localStorage.getItem("currPage")
                );
              }}
              value={height}
            ></input>
          </MenuItem>

          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <h4 style={{ margin: "3px" }}>Text Align: </h4>{" "}
            <input
              onChange={(e) => {
                setAlign((e.target.value).trim().toLowerCase());
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  e.target.value,
                  width,
                  [
                    {
                      Content: content,
                      Color: color,
                      Align: (e.target.value).trim().toLowerCase(),
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={align}
            ></input>
          </MenuItem>

          {/* <MenuItem>
            <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
            <input
              onChange={(e) => {
                e.preventDefault();
                setWidth(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  e.target.value,
                  [...props.dataEntry.extraData]
                );
              }}
              value={width}
            ></input>
          </MenuItem>
          <MenuItem>
            <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
            <input
              onChange={(e) => {
                setHeight(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  e.target.value,
                  width,
                  [...props.dataEntry.extraData]
                );
              }}
              value={height}
            ></input>
          </MenuItem> */}

          <MenuItem
            onClick={(e) => {
              props.removeElementFromDATA(props.dataEntry.elementId);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </Rnd>
    </div>
  );
};

export default DraggableParagraph;
