import React from "react";

//utility function
function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

const NonDraggableRadioInput = (props) => {
  const style = {
    position: "absolute",
    top: `${props.data.positionY + 70}px`,
    left: `${props.data.positionX}px`,
    height: `${props.data.height}`,
    width: `${props.data.width}`,
  };
  return (
    <div style={style}>
      <input
        type="radio"
        name={props.data.extraData[0].radioName}
        value={camelize(props.data.extraData[0].radioLabel.trim()).replace(
          / /g,
          ""
        )}
      ></input>
      <label
        htmlFor={camelize(props.data.extraData[0].radioLabel.trim()).replace(
          / /g,
          ""
        )}
      >
        {props.data.extraData[0].radioLabel}
      </label>
    </div>
  );
};

export default NonDraggableRadioInput;
