import React from 'react';

function SurWhey() {
  return <div>
    <h1>
    The SurWhey Section is still Under Development.
    </h1>
  </div>;
}

export default SurWhey;
