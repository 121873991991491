import React from 'react'

const NonDraggableImage = (props) => {
    const style={
        position: "absolute",
        top: `${props.data.positionY+ 70}px`,
        left: `${props.data.positionX}px`,
        height: `${props.data.height}`,
        width: `${props.data.width}`
    }
  return (
    <img alt="displayimg" src={props.data.extraData[0].imageURL} style={style}></img>
  )
}

export default NonDraggableImage