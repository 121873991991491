import React from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
// import { Rnd } from "react-rnd";
import MoreVertIcon from '@mui/icons-material/MoreVert';




const DraggableHeader = (props) => {
  const [width] = useState(props.dataEntry.width);
  const [height] = useState(props.dataEntry.height);
  const [color, setColor] = useState(props.dataEntry.extraData[0].Color);
  const [content, setContent] = useState(props.dataEntry.extraData[0].Content);
  const [position] = useState({
    x: props.dataEntry.positionX,
    y: props.dataEntry.positionY,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      {/* <Rnd
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
          props.refreshDATA(
            props.dataEntry.elementId,
            props.dataEntry.elementCode,
            d.x,
            d.y,
            height,
            width,
            [...props.dataEntry.extraData]
          );
        }}
      >
        <h1 style={{ lineHeight: height, width: width }}>{content}</h1> */}
        
        <nav className="navbar navbar-light" style={{backgroundColor:color, borderRadius:"2px"}}>
        <h3 style={{color:"white", fontFamily:"verdana"}}>{content}</h3>
        </nav>
        

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <h4 style={{ margin: "3px" }}>Content: </h4>{" "}
            <input
              onChange={(e) => {
                setContent(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [{ Content: e.target.value, Color:color }],
                  localStorage.getItem("currPage")
                );
              }}
              value={content}
            ></input>
          </MenuItem>
          <MenuItem>
          <h4 style={{ margin: "3px" }}>Color: </h4>{" "}
            <input type="color" id="favcolor" name="favcolor" style={{width:"150pt"}}
              onChange={(e) => {
                setColor(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  //e.target.value
                  [{ Content: content,Color: e.target.value }],
                  localStorage.getItem("currPage")
                );
              }}
              value={color}
            ></input>
          </MenuItem>
          {/* <MenuItem>
            <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
            <input
              onChange={(e) => {
                e.preventDefault();
                setWidth(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  e.target.value,
                  [...props.dataEntry.extraData]
                );
              }}
              value={width}
            ></input>
          </MenuItem>
          <MenuItem>
            <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
            <input
              onChange={(e) => {
                setHeight(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  e.target.value,
                  width,
                  [...props.dataEntry.extraData]
                );
              }}
              value={height}
            ></input>
          </MenuItem> */}

          <MenuItem
            onClick={(e) => {
              props.removeElementFromDATA(props.dataEntry.elementId);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      {/* </Rnd> */}
    </div>
  );
};

export default DraggableHeader;