import React from "react";
import uniqid from "uniqid";

const NonDraggableCarousel = (props) => {

  const height = props.data.height;
  const width = props.data.width;
  const link1 = props.data.extraData[0].link1;
  const link2 = props.data.extraData[0].link2;
  const link3 = props.data.extraData[0].link3;
  const link4 = props.data.extraData[0].link4;
  const link5 = props.data.extraData[0].link5;

  const id = uniqid();

  return (
    <div
      id={`${id}`}
      class="carousel slide"
      data-ride="carousel"
      style={{
        height: `${height}`,
        width: `${width}`,
        position: "absolute",
        top: `${props.data.positionY + 70}px`,
        left: `${props.data.positionX}px`,
      }}
    >
      <div
        class="carousel-inner"
        style={{ height: `${height}`, width: `${width}` }}
      >
        <div
          class="carousel-item active"
          style={{ height: `${height}`, width: `${width}` }}
        >
          <img
            style={{ height: `${height}`, width: `${width}` }}
            class="d-block w-100"
            src={link1}
            alt="First slide"
          ></img>
        </div>

        {link2.trim() !== "" ? (
          <div
            class="carousel-item"
            style={{ height: `${height}`, width: `${width}` }}
          >
            <img
              style={{ height: `${height}`, width: `${width}` }}
              class="d-block w-100"
              src={link2}
              alt="First slide"
            ></img>
          </div>
        ) : (
          <></>
        )}

        {link3.trim() !== "" ? (
          <div
            class="carousel-item"
            style={{ height: `${height}`, width: `${width}` }}
          >
            <img
              style={{ height: `${height}`, width: `${width}` }}
              class="d-block w-100"
              src={link3}
              alt="First slide"
            ></img>
          </div>
        ) : (
          <></>
        )}

        {link4.trim() !== "" ? (
          <div
            class="carousel-item"
            style={{ height: `${height}`, width: `${width}` }}
          >
            <img
              style={{ height: `${height}`, width: `${width}` }}
              class="d-block w-100"
              src={link4}
              alt="First slide"
            ></img>
          </div>
        ) : (
          <></>
        )}

        {link5.trim() !== "" ? (
          <div
            class="carousel-item"
            style={{ height: `${height}`, width: `${width}` }}
          >
            <img
              style={{ height: `${height}`, width: `${width}` }}
              class="d-block w-100"
              src={link5}
              alt="First slide"
            ></img>
          </div>
        ) : (
          <></>
        )}
      </div>
      <a
        class="carousel-control-prev"
        href={`#${id}`}
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href={`#${id}`}
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  );
};

export default NonDraggableCarousel;
