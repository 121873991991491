import React from "react";
import {rdb } from "../../firebase";
import NonDraggableInput from "../NonDraggableInput/NonDraggableInput";
import NonDraggableHeading from "../NonDraggableHeading/NonDraggableHeading";
import NonDraggableButton from "../NonDraggableButton/NonDraggableButton";
import NonDraggableHeader from "../NonDraggableHeader/NonDraggableHeader";
import NonDraggableParagraph from "../NonDraggableParagraph/NonDraggableParagraph";
import NonDraggableImage from "../NonDraggableImage/NonDraggableImage";
// import {projID} from "../AppWeb";
import "./Publish.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ref, onValue} from "firebase/database";
import NonDraggableCheckBox from "../NonDraggableCheckBox/NonDraggableCheckBox";
import NonDraggableRadioInput from "../NonDraggableRadioInput/NonDraggableRadioInput";
import NonDraggableRadioGroup from "../NonDraggableRadioGroup/NonDraggableRadioGroup";
import NonDraggableMap from "../NonDraggableMap/NonDraggableMap";
import NonDraggabeIP from "../NonDraggableIP/NonDraggabeIP";
import NonDraggableDateAndTimeInput from "../NonDraggableDateAndTimeInput/NonDraggableDateAndTimeInput";
import NonDraggableEmailInput from "../NonDraggableEmailInput/NonDraggableEmailInput";
import NonDraggableTelephoneInput from "../NonDraggableTelephoneInput/NonDraggableTelephoneInput";
import RouteMenu from "../RouteMenu/ReouteMenu";
import NonDraggableTable from "../NonDraggableTable/NonDraggableTable";
import NonDraggableInstagram from "../NonDraggableInstagram/NonDraggableInstagram";
import NonDraggableFacebook from "../NonDraggableFacebook/NonDraggableFacebook";
import NonDraggableLinkedin from "../NonDraggableLinkedin/NonDraggableLinkedin";
import NonDraggableTwitter from "../NonDraggableTwitter/NonDraggableTwitter";
import NonDraggableYoutube from "../NonDraggableYoutube/NonDraggableYoutube";
import NonDraggableCarousel from "../NonDraggableCarousel/NonDraggableCarousel";

const Publish = () => {
  const [retrievedDATA, setRetrievedDATA] = React.useState([]);
  const [extraPages, setExtraPages] = React.useState([]);
  // const extrPgs = React.useRef();
  // const rtrvdData = React.useRef();
  const projID_1 = localStorage.getItem("projID");
  const addPage = (pagename)=>{
    setExtraPages((prev)=>{
      return [
        ...prev,
        pagename
      ]
    })
  }
  React.useEffect(() => {
    // getDoc(doc(db, "Projects", projID_1)).then((docSnap) => {
    //   if (docSnap.exists()) {
    //     //   console.log("Document data:", docSnap.data());
    //     const publishData = docSnap.data();
    //     setRetrievedDATA(publishData.Data);
    //     console.log(publishData);
    //     // console.log(publishData.Data[0]);
    //   }
    // });

    const starCountRef = ref(rdb, "projects/" + projID_1);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("DATA: " , data);
      // updateStarCount(postElement, data);
      setRetrievedDATA(data.TEMPLATES[data.TEMPLATES.length - 1]);
      setExtraPages(data.pages);
    });
  }, [projID_1]);


  return (
    <div className="pubilsh__canvas_wrapper">
      <div className="publish__goback">
        <Link className="publish__goback_link" to="/appwebcreate">
          <Button className="publish__goback_button" variant="contained">
            <ArrowBackIcon />
          </Button>
        </Link>
        <RouteMenu root="publish" extraPagesProp={extraPages} hidden={true} addExtraPage={addPage} />
      </div>
      <div className="pubish__canvas">
        {retrievedDATA.map((dataEntry) => {
          if (dataEntry.elementCode === "00"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableInput key={dataEntry.elementId} data={dataEntry} />
            );
          } else if (dataEntry.elementCode === "01"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableHeading key={dataEntry.elementId} data={dataEntry} />
            );
          } else if (dataEntry.elementCode === "02"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableButton key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "03"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableHeader key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "04"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableParagraph key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "05"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableImage key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "06"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableCheckBox key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "07"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableRadioInput key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "09"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableRadioGroup key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "10"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableMap key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "11"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggabeIP key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "12"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableDateAndTimeInput key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "13"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableEmailInput key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "14"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableTelephoneInput key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "15"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableTable key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "16"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableInstagram key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "17"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableFacebook key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "18"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableLinkedin key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "19"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableTwitter key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "20"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableYoutube key={dataEntry.elementId} data={dataEntry} />
            );
          }
          else if (dataEntry.elementCode === "21"&& dataEntry.page===localStorage.getItem("currPage")) {
            return (
              <NonDraggableCarousel key={dataEntry.elementId} data={dataEntry} />
            );
          }
          return (<></>);
        })}
      </div>
    </div>
  );
};

export default Publish;
