import React from 'react'
import axios from "axios"

const NonDraggabeIP = (props) => {
    const [IP, setIP] = React.useState();
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
      }
      React.useEffect( () => {
        getData()
      }, [])
    const style={
        position: "absolute",
        top: `${props.data.positionY + 70}px`,
        left: `${props.data.positionX}px`,
        height: `${props.data.height}`,
        width: `${props.data.width}`
    }
  return (
        <div style={style}>
            <h1>Your IP Address is:</h1><br/>
            {IP}
        </div>
  )
}

export default NonDraggabeIP