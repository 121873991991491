import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useState } from "react";
import logo from "./Images/logo.png";
import Palette from "./Palette/Palette";
import DraggableInput from "./DraggableInput/DraggableInput";
import DraggableHeading from "./DraggableHeading/DraggableHeading";
import DraggableButton from "./DraggableButton/DraggableButton";
import DraggableHeader from "./DraggableHeader/DraggableHeader";
import DraggableParagraph from "./DraggableParagraph/DraggableParagraph";
import DraggableImage from "./DraggableImage/DraggableImage";
import DraggableCheckBox from "./DraggableCheckBox/DraggableCheckBox";
import "./AppWebCreate.css";
import history from "../history";
import { rdb } from "../firebase";
import { ref, onValue } from "firebase/database";
import DraggableRadioInput from "./DraggableRadioInput/DraggableRadioInput";
import { DraggableDropdownList } from "./DraggableDropdownList/DraggableDropdownList";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import DraggableRadioGroup from "./DraggableRadioGroup/DraggableRadioGroup";
import DraggableMap from "./DraggableMap/DraggableMap";
import DraggableIP from "./DraggableIP/DraggableIP";
import DraggableDateAndTimeInput from "./DraggableDateAndTimeInput/DraggableDateAndTimeInput";
import DraggableEmailInput from "./DraggableEmailInput/DraggableEmailInput";
import DraggableTelephoneInput from "./DraggableTelephoneInput/DraggableTelephoneInput";
import RouteMenu from "./RouteMenu/ReouteMenu";
import DraggableTable from "./DraggableTable/DraggableTable";
import DraggableInstagram from "./DraggableInstagram/DraggableInstagram";
import DraggableFacebook from "./DraggableFacebook/DraggableFacebook";
import DraggableLinkedin from "./DraggableLinkedin/DraggableLinkedin";
import DraggableTwitter from "./DraggableTwitter/DraggableTwitter";
import DraggableYoutube from "./DraggableYoutube/DraggableYoutube";
import DraggableCarousel from "./DraggableCarousel/DraggableCarousel";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight() {
  var [DATA, setDATA] = useState([]);
  var [canvasHeight, setCanvasHeight] = useState(0);
  var [extraPages, setExtraPages] = useState([""]);


  const addPage = (pagename)=>{
    setExtraPages((prev)=>{
      return [
        ...prev,
        pagename
      ]
    })
  }

  React.useEffect(()=>{
    localStorage.setItem("currPage", "home");
  },[])
  //23-03-2022
  // var [allTemplates, setAllTemplates] = useState([]);
  const ALLTEMPS = React.useRef([]);

  //

  if (!localStorage.getItem("user")) {
    alert("You need to login first.");
    history.push("/");
  }
  const projID_1 = localStorage.getItem("projID");

  React.useEffect(() => {
    // console.log("first");
    const starCountRef = ref(rdb, "projects/" + projID_1);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // console.log("fetched data : ", data);
        ALLTEMPS.current = data.TEMPLATES;
        // console.log("ALLTEMPS : ", ALLTEMPS);
        if (ALLTEMPS.current.length >= 1) {
          setDATA(ALLTEMPS.current[ALLTEMPS.current.length - 1]);
        }

        setExtraPages(data.pages);
      }
    });
  }, [projID_1]);

  // React.useEffect(()=>{
  //   console.log("second");
  //   if(allTemplates.length >= 1){
  //     setDATA(allTemplates[allTemplates.length - 1]);
  //   }
  // },[projID_1,allTemplates])

  const removeElementFromDATA = (elementID) => {
    setDATA((prev) => {
      const values = [...prev];
      for (var i = 0; i < values.length; i++) {
        if (values[i].elementId === elementID) {
          values.splice(i, 1);
          break;
        }
      }
      prev = values;
      return prev;
    });
  };

  const refreshDATA = (elementID, elementCode, posX, posY, h, w, extraData, pageA) => {
    console.log(canvasHeight);
    setDATA((prev) => {
      for (var i = 0; i < prev.length; i++) {
        if (prev[i].elementId === elementID) {
          prev.splice(i, 1);
          break;
        }
      }
      prev = [
        ...prev,
        {
          elementId: elementID,
          elementCode: elementCode,
          height: h,
          width: w,
          positionX: posX,
          positionY: posY,
          extraData: [...extraData],
          page: pageA
        },
      ];
      return prev;
    });
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // console.log("Data : ", DATA);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            <a className="navbar-brand canvas-title" href="/">
              <img
                src={logo}
                className="d-inline-block align-top canvas-emblem"
                alt="canvas-emblem"
                style={{ margin: "5px" }}
              ></img>
              <div style={{ display: "inline-block", fontSize: "27px", marginLeft: "10px" }}>
                AppWeb
              </div>{" "}
              <div style={{ display: "inline-block", fontSize: "14px" }}>
                From OmnéVu
              </div>
            </a>
          </Typography>
          <RouteMenu root="appwebcreate" extraPagesProp={extraPages} addExtraPage={addPage} hidden={false} />
          <Button
            className="deploy-button"
            style={{ color: "white", marginRight: "60px" }}
          >
            <Link
              className="deploy-text"
              target="_blank"
              to={`/deploy/${projID_1}`}
            >
              Deploy
            </Link>
          </Button>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Main open={open}>
        <div className="canvas" style={{ height: `${canvasHeight}px` }}>
          {DATA.map((dataEntry) => {
            if (dataEntry.elementCode === "00" && dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableInput
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "01"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableHeading
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "02"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableButton
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "03"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableHeader
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "04"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableParagraph
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "05"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableImage
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "06"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableCheckBox
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "07"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableRadioInput
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "08"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableDropdownList
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            } else if (dataEntry.elementCode === "09"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableRadioGroup
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }else if (dataEntry.elementCode === "10"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableMap
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }else if (dataEntry.elementCode === "11"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableIP
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }else if (dataEntry.elementCode === "12"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableDateAndTimeInput
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "13"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableEmailInput
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "14"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableTelephoneInput
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "15"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableTable
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "16"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableInstagram
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "17"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableFacebook
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "18"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableLinkedin
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "19"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableTwitter
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "20"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableYoutube
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            else if (dataEntry.elementCode === "21"&& dataEntry.page===localStorage.getItem("currPage")) {
              return (
                <DraggableCarousel
                  key={dataEntry.elementId}
                  dataEntry={dataEntry}
                  refreshDATA={refreshDATA}
                  removeElementFromDATA={removeElementFromDATA}
                />
              );
            }
            return (<></>);
          })}
        </div>
      </Main>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />
        <div className="palette">
          <Palette
            setCanvasHeight={setCanvasHeight}
            DATA={DATA}
            setDATA={setDATA}
            allTemplates={ALLTEMPS.current}
            extraPagesProp={extraPages}
          ></Palette>
        </div>
      </Drawer>
    </Box>
  );
}
