import React from 'react'

const NonDraggableEmailInput = (props) => {
    const style={
        position: "absolute",
        top: `${props.data.positionY+ 70}px`,
        left: `${props.data.positionX}px`,
        height: `${props.data.height}`,
        width: `${props.data.width}`
    }
  return (
        <input type="email" style={style}></input>
  )
}

export default NonDraggableEmailInput