import React from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Rnd } from "react-rnd";
import { quantize } from "../QUANTIZATION/quantization";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import uniqid from "uniqid";

const DraggableCarousel = (props) => {
  const [width, setWidth] = useState(props.dataEntry.width);
  const [height, setHeight] = useState(props.dataEntry.height);
  const [position, setPosition] = useState({
    x: props.dataEntry.positionX,
    y: props.dataEntry.positionY,
  });

  const [link1, setLink1] = useState(props.dataEntry.extraData[0].link1);
  const [link2, setLink2] = useState(props.dataEntry.extraData[0].link2);
  const [link3, setLink3] = useState(props.dataEntry.extraData[0].link3);
  const [link4, setLink4] = useState(props.dataEntry.extraData[0].link4);
  const [link5, setLink5] = useState(props.dataEntry.extraData[0].link5);

  const id = uniqid();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Rnd
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          const newDims = quantize(d.x, d.y);
          setPosition(newDims);
          props.refreshDATA(
            props.dataEntry.elementId,
            props.dataEntry.elementCode,
            newDims.x,
            newDims.y,
            height,
            width,
            [...props.dataEntry.extraData],
            localStorage.getItem("currPage")
          );
        }}
      >
        {/* Main Element Starts */}

        <div
          id={`${id}`}
          class="carousel slide"
          data-ride="carousel"
          style={{ height: `${height}`, width: `${width}` }}
        >
          <div
            class="carousel-inner"
            style={{ height: `${height}`, width: `${width}` }}
          >
            <div
              class="carousel-item active"
              style={{ height: `${height}`, width: `${width}` }}
            >
              <img
                style={{ height: `${height}`, width: `${width}` }}
                class="d-block w-100"
                src={link1}
                alt="First slide"
              ></img>
            </div>

            {link2.trim() !== "" ? (
              <div
                class="carousel-item"
                style={{ height: `${height}`, width: `${width}` }}
              >
                <img
                  style={{ height: `${height}`, width: `${width}` }}
                  class="d-block w-100"
                  src={link2}
                  alt="First slide"
                ></img>
              </div>
            ) : (
              <></>
            )}

            {link3.trim() !== "" ? (
              <div
                class="carousel-item"
                style={{ height: `${height}`, width: `${width}` }}
              >
                <img
                  style={{ height: `${height}`, width: `${width}` }}
                  class="d-block w-100"
                  src={link3}
                  alt="First slide"
                ></img>
              </div>
            ) : (
              <></>
            )}

            {link4.trim() !== "" ? (
              <div
                class="carousel-item"
                style={{ height: `${height}`, width: `${width}` }}
              >
                <img
                  style={{ height: `${height}`, width: `${width}` }}
                  class="d-block w-100"
                  src={link4}
                  alt="First slide"
                ></img>
              </div>
            ) : (
              <></>
            )}

            {link5.trim() !== "" ? (
              <div
                class="carousel-item"
                style={{ height: `${height}`, width: `${width}` }}
              >
                <img
                  style={{ height: `${height}`, width: `${width}` }}
                  class="d-block w-100"
                  src={link5}
                  alt="First slide"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
          <a
            class="carousel-control-prev"
            href={`#${id}`}
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href={`#${id}`}
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        {/* Main Element Ends */}

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{ borderRadius: "100%" }}
          disableRipple={true}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem>
            <h4 style={{ margin: "3px" }}>Width: </h4>{" "}
            <input
              onChange={(e) => {
                setWidth(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  e.target.value,
                  [...props.dataEntry.extraData],
                  localStorage.getItem("currPage")
                );
              }}
              value={width}
            ></input>
          </MenuItem>
          <MenuItem>
            <h4 style={{ margin: "3px" }}>Height: </h4>{" "}
            <input
              onChange={(e) => {
                setHeight(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  e.target.value,
                  width,
                  [...props.dataEntry.extraData],
                  localStorage.getItem("currPage")
                );
              }}
              value={height}
            ></input>
          </MenuItem>

          <MenuItem>
            <h4 style={{ margin: "3px" }}>Link 1: </h4>{" "}
            <input
              onChange={(e) => {
                setLink1(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [
                    {
                      ...props.dataEntry.extraData[0],
                      link1: `${e.target.value}`,
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={link1}
            ></input>
          </MenuItem>

          <MenuItem>
            <h4 style={{ margin: "3px" }}>Link 2: </h4>{" "}
            <input
              onChange={(e) => {
                setLink2(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [
                    {
                      ...props.dataEntry.extraData[0],
                      link2: `${e.target.value}`,
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={link2}
            ></input>
          </MenuItem>

          <MenuItem>
            <h4 style={{ margin: "3px" }}>Link 3: </h4>{" "}
            <input
              onChange={(e) => {
                setLink3(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [
                    {
                      ...props.dataEntry.extraData[0],
                      link3: `${e.target.value}`,
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={link3}
            ></input>
          </MenuItem>

          <MenuItem>
            <h4 style={{ margin: "3px" }}>Link 4: </h4>{" "}
            <input
              onChange={(e) => {
                setLink4(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [
                    {
                      ...props.dataEntry.extraData[0],
                      link4: `${e.target.value}`,
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={link4}
            ></input>
          </MenuItem>

          <MenuItem>
            <h4 style={{ margin: "3px" }}>Link 5: </h4>{" "}
            <input
              onChange={(e) => {
                setLink5(e.target.value);
                props.refreshDATA(
                  props.dataEntry.elementId,
                  props.dataEntry.elementCode,
                  position.x,
                  position.y,
                  height,
                  width,
                  [
                    {
                      ...props.dataEntry.extraData[0],
                      link5: `${e.target.value}`,
                    },
                  ],
                  localStorage.getItem("currPage")
                );
              }}
              value={link5}
            ></input>
          </MenuItem>

          <MenuItem
            onClick={(e) => {
              props.removeElementFromDATA(props.dataEntry.elementId);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </Rnd>
    </div>
  );
};

export default DraggableCarousel;
